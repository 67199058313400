import React, { useState, Fragment } from 'react';
import Grid from "@mui/material/Grid";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Navbar from '../../components/Navbar';
import Logo from '../../images/logo-big.png';
import { useDispatch } from 'react-redux';
import { signIn } from '../../store/actions/authActions';
import { Helmet } from "react-helmet";
import CustomLoader from '../../components/CustomLoader';
import './style.scss';

const LoginPage = () => {

    const push = useNavigate()
    const dispatch = useDispatch();

    const [value, setValue] = useState({
        email: '',
        password: '',
        remember: false,
    });
    const [isCompleted, setCompleted] = useState(false);

    const changeHandler = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value });
        validator.showMessages();
    };

    const [validator] = React.useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));

    const submitForm = (e) => {
        e.preventDefault();
        setCompleted(true);
        if (validator.allValid()) {
            validator.hideMessages();
            dispatch(signIn(value, push, setCompleted));
        } else {
            setCompleted(false);
            validator.showMessages();
            toast.error('Empty field is not allowed!');
        }
    };

    return (
        <Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Login</title>
                <link rel="canonical" href="https://gsi.ie/login" />
                <meta name='description' content='Your journey begins with a simple click. Login today.' />
                <meta property="og:title" content="Login" />
                <meta property="og:description" content="Your journey begins with a simple click. Login today." />
                <meta name="twitter:title" content="Login" />
                <meta name="twitter:description" content="Your journey begins with a simple click. Login today." />
            </Helmet>
            <Navbar hclass={'wpo-header-style-3'} Logo={Logo} />
            <CustomLoader show={isCompleted} />
            <Grid className="loginWrapper">
                <Grid className="loginForm">
                    <h2>Sign In</h2>
                    <p>Sign in to your account</p>
                    <form onSubmit={submitForm}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    className="inputOutline"
                                    fullWidth
                                    placeholder="E-mail"
                                    value={value.email}
                                    variant="outlined"
                                    name="email"
                                    label="E-mail"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onBlur={(e) => changeHandler(e)}
                                    onChange={(e) => changeHandler(e)}
                                />
                                {validator.message('email', value.email, 'required|email')}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    className="inputOutline"
                                    fullWidth
                                    placeholder="Password"
                                    value={value.password}
                                    variant="outlined"
                                    name="password"
                                    type="password"
                                    label="Password"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onBlur={(e) => changeHandler(e)}
                                    onChange={(e) => changeHandler(e)}
                                />
                                {validator.message('password', value.password, 'required')}
                            </Grid>
                            <Grid item xs={12}>
                                <Grid className="formFooter">
                                    <Button fullWidth className="cBtnTheme" type="submit">Login</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </Fragment>
    )
};

export default LoginPage;